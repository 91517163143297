import React from 'react';
import PropTypes from 'prop-types';

const FloatingTextbox = ({ children, text = null }) => (
  <span className="position-relative opacity-transition-anchor">
    {children}
    {text && (
      <span className="bg-text opacity-0 opacity-transition pointer-events-none position-absolute px-2 py-1 radius-0_125 right-0 text-white text-0_75 top-6 z-2">
        {text}
      </span>
    )}
  </span>
);

FloatingTextbox.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default FloatingTextbox;
