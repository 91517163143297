import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CompareButton } from 'components/compare';
import { MobileNav, Nav, Navbar } from 'components/navigation';
import { useUser } from 'libs';
import { SpotlightLogoIcon } from 'icons';
import ChatWithUs from './ChatWithUs';
import SavedFilterPanel from './SavedFilterPanel';

const Topbar = () => {
  const user = useUser();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.startsWith('/rates')) setPageTitle('Rates');
    if (pathname.startsWith('/lenders')) setPageTitle('Lenders');
    if (pathname.startsWith('/guidelines')) setPageTitle('Policies');
    if (pathname.startsWith('/alternative-lending')) setPageTitle('Alternative Lending');
    if (pathname === '/settings') setPageTitle('Profile');
    if (pathname.startsWith('/settings/brokerages')) setPageTitle('Brokerages');
    if (pathname.startsWith('/settings/payment-method')) {
      setPageTitle('Payment Method');
    }
    if (pathname.startsWith('/settings/subscriptions')) {
      setPageTitle(user.features.is_pro ? 'Subscriptions' : 'Upgrade');
    }
    if (pathname.startsWith('/ai')) setPageTitle('AI Assistant');
    setMobileNavOpen(false);
  }, [pathname, user.features.is_pro]);

  const toggleOpenMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const showRateButtons = ['/rates/lenders', '/rates/products'].some((path) => pathname.startsWith(path));
  const showChatWithUs = !user.features.is_pro && pathname.startsWith('/settings/subscriptions');

  const logo = user.brokerage?.logo_path;

  return (
    <Navbar className="topbar" data-testid="layout-topbar">
      <div className="topbar-logo">
        <SpotlightLogoIcon className="w-6 text-white" />
      </div>
      <div className="topbar-content">
        {logo ? (
          <Navbar.Brand image={logo} />
        ) : (
          <h1 className="topbar-logo-title">
            Lender Spotlight {!user.features.is_pro && <span className="topbar-content__basic-label">Basic</span>}
          </h1>
        )}
        <h2 className="topbar-content__page-title" data-testid="topbar-content-page-title">
          {pageTitle}
        </h2>
        <Nav className="flex-row">
          {showRateButtons && user.features.show_compare && <CompareButton />}
          {showRateButtons && !mobileNavOpen && <SavedFilterPanel />}
          {showChatWithUs && <ChatWithUs />}
          <div id="topbar-portal" />
        </Nav>
      </div>
      <MobileNav
        hasPaymentMethod={user.features.has_payment_method}
        isOpen={mobileNavOpen}
        isPro={user.features.is_pro}
        onToggle={toggleOpenMobileNav}
      />
    </Navbar>
  );
};

export default Topbar;
