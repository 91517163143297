import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowTrendUp,
  faArrowUpRightFromSquare,
  faAt,
  faBars,
  faBriefcase,
  faCalculator,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCirclePlus,
  faCircleQuestion,
  faCircleXmark,
  faCopy,
  faDownload,
  faEarthAmericas,
  faEllipsisVertical,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFax,
  faFile,
  faFileLines,
  faFiles,
  faFloppyDisk,
  faHouse,
  faLaptop,
  faLink,
  faMobile,
  faMoneyBill,
  faPencil,
  faPhone,
  faScaleUnbalancedFlip,
  faTableCells,
  faThumbsDown,
  faThumbsUp,
  faTrashCan,
  faUser,
  faUsers,
  faWindow,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as fasCircleCheck, faCirclePlus as fasCirclePlus } from '@fortawesome/pro-solid-svg-icons';

export default library.add(
  faArrowLeft,
  faArrowTrendUp,
  faArrowUpRightFromSquare,
  faAt,
  faBars,
  faBriefcase,
  faCalculator,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCirclePlus,
  faCircleQuestion,
  faCircleXmark,
  faCopy,
  faDownload,
  faEarthAmericas,
  faEllipsisVertical,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFax,
  faFile,
  faFileLines,
  faFiles,
  faFloppyDisk,
  faHouse,
  faLaptop,
  faLink,
  faMobile,
  faMoneyBill,
  faPencil,
  faPhone,
  faScaleUnbalancedFlip,
  faTableCells,
  faThumbsDown,
  faThumbsUp,
  faTrashCan,
  faUser,
  faUsers,
  faWindow,
  faXmark,
  fasCircleCheck,
  fasCirclePlus
);
